<template>
  <div>
    <v-tabs v-model="tab" background-color="transparent" color="basil">
      <v-tab v-for="menu in menuList" :key="menu.value">
        {{ menu.text }}
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab" class="py-3">
      <v-tab-item v-for="menu in menuList" :key="menu.value">
        <v-card color="basil" flat>
          <component v-bind:is="menu.component"></component>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
  </div>
</template>

<script>
import PointUsageTab from './PointUsageTab.vue';
import PointOrderTab from './PointOrderTab.vue';

export default {
  components: {
    PointUsageTab,
    PointOrderTab
  },
  data() {
    return {
      tab: null,
      menuList: [
        {
          text: '포인트 내역',
          value: 'consume',
          component: 'PointUsageTab'
        },
        {
          text: '결제 내역',
          value: 'payment',
          component: 'PointOrderTab'
        }
      ]
    };
  },
  mounted() {
    this.tab = this.menuList[0].value;
  }
};
</script>

<style></style>
