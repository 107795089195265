<template>
  <div class="pa-5">
    <v-row>
      <v-col cols="12" lg="4">
        <v-card class="rounded-lg">
          <v-card-title> 총 잔액 (VAT별도) </v-card-title>
          <v-card-text>
            <div
              class="d-flex align-center justify-space-between"
              style="min-height: 50px"
            >
              <v-progress-circular v-if="apiLoading" indeterminate>
              </v-progress-circular>
              <span v-else style="font-size: 28px; font-weight: bold">
                {{ pointText(totalPoint) }}
              </span>
              <v-btn dark @click="handleChargeClick()"> 충전하기 </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card class="rounded-lg">
          <v-card-title> 유상 잔액 (VAT별도) </v-card-title>
          <v-card-text>
            <div
              class="d-flex align-center justify-space-between"
              style="min-height: 50px"
            >
              <v-progress-circular v-if="apiLoading" indeterminate>
              </v-progress-circular>
              <span v-else style="font-size: 28px; font-weight: bold">
                {{ pointText(creditPoint) }}
              </span>
              <!-- <v-btn
                v-if="isEnableRefundButton"
                @click="handleRefundClick()"
                dark
              >
                환불하기
              </v-btn> -->
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" lg="4">
        <v-card class="rounded-lg">
          <v-card-title> 무상 잔액 </v-card-title>
          <v-card-text>
            <div
              class="d-flex align-center justify-space-between"
              style="min-height: 50px"
            >
              <v-progress-circular v-if="apiLoading" indeterminate>
              </v-progress-circular>
              <span v-else style="font-size: 28px; font-weight: bold">
                {{ pointText(nonsettleFreePoint) }}
              </span>
              <v-btn
                v-if="isEnableUseButton"
                @click="handleUseFreePointClick()"
                dark
              >
                사용하기
              </v-btn>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <ul>
          <li>
            명시된 "충전 잔액"은 유상잔액과 무상 잔액이 합쳐진 금액입니다.
          </li>
          <li>"충전 잔액"은 유상 잔액부터 소진됩니다.</li>
          <li>
            현재의 표시된 금액은 충전한 금액에서 캠페인 진행 금액을 정산한
            충전금에 대한 잔액입니다.
          </li>
          <!-- <li>
            충전 금액은 전일 진행된 캠페인 금액을 계산하여, 남은 금액을 익일
            오전 11시에 업데이트 하고 있습니다.<br />
            (예시: 2021년 10월 31일 진행된 캠페인 결과 및 정산 금액 → 2021년
            11월 1일 11시에 캠페인 진행 금액을 계산, 남은 금액을 오전 11시에
            업데이트)
          </li> -->
          <li>
            무상 잔액은 사용하기로 신청하게 될 경우, 영업일 기준 1일 이내로 처리
            될 예정입니다.
          </li>
          <li>
            "환불하기" 신청할 경우, 유상 잔액에 대해 환불될 예정이며, 운영팀에서
            금액과 내용 확인하여 담당자에게 연락드릴 예정입니다.
          </li>
        </ul>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <PaymentDetailTab />
      </v-col>
    </v-row>
    <CreditCardBill v-if="creditCardBillDialog" />
    <BankTransferBill v-if="bankTransferBillDialog" />
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { refundPointApi, settleFreePointApi } from '@/api/partner/UserAPI';
import PaymentDetailTab from './components/PaymentDetailTab.vue';
import CreditCardBill from '@/components/payment/CreditCardBill.vue';
import BankTransferBill from '@/components/payment/BankTransferBill.vue';

export default {
  components: {
    PaymentDetailTab,
    CreditCardBill,
    BankTransferBill
  },
  data() {
    return {
      creditCardDialog: false
    };
  },
  computed: {
    ...mapGetters({
      totalPoint: 'user/totalPoint',
      creditPoint: 'user/creditPoint',
      nonsettleFreePoint: 'user/nonsettleFreePoint',
      apiLoading: 'user/apiLoading',
      creditCardBillDialog: 'dialog/creditCardBillDialog',
      bankTransferBillDialog: 'dialog/bankTransferBillDialog'
    }),
    isEnableRefundButton() {
      return this.creditPoint > 0;
    },
    isEnableUseButton() {
      return this.nonsettleFreePoint > 0;
    }
  },
  methods: {
    ...mapActions({
      openRechargeDialog: 'dialog/openRechargeDialog'
    }),
    pointText(point) {
      if (point == 'undefined' || point == null) {
        return `N/A`;
      } else if (point >= 0) {
        return `${point.toLocaleString()} 원`;
      } else {
        return `N/A`;
      }
    },
    handleChargeClick() {
      this.openRechargeDialog();
    },
    handleRefundClick() {
      this.$Swal
        .fire({
          icon: 'info',
          iconColor: '#f00',
          html: `<h3><strong style="color:red">환불을 신청하시면, 신청 시점부터 노티아이 사용이 정지됩니다.</strong></br></br>그래도 환불을 신청하시겠습니까?</h3>`,
          confirmButtonText: '신청하기',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const [error] = await refundPointApi();
            if (error) {
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return true;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Swal.fire({
              icon: 'success',
              title: `환불요청이 접수되었습니다.`,
              html: '<h3>환불 금액은 금일 현재 시간까지 정산 후, 노티아이 운영팀에서 영업일 3~5일 내로 정산 금액 정리하여 담당자님에게 연락드릴 예정입니다.</h3>',
              confirmButtonText: '창닫기'
            });
          }
        });
    },
    handleUseFreePointClick() {
      const html = `<h3>현재 고객님에게 제공된 무상 금액은</br></br><strong>${this.nonsettleFreePoint.toLocaleString()} </strong>원 </br>입니다.<br/></br>무상 금액 사용을 신청하시겠습니까?</h3>`;
      this.$Swal
        .fire({
          icon: 'info',
          html: html,
          confirmButtonText: '신청하기',
          showCancelButton: true,
          cancelButtonText: '취소',
          showLoaderOnConfirm: true,
          preConfirm: async () => {
            const [error] = await settleFreePointApi();
            if (error) {
              this.$Swal.showValidationMessage(`요청 실패`);
            } else {
              return true;
            }
          },
          allowOutsideClick: () => !this.$Swal.isLoading()
        })
        .then(result => {
          if (result.isConfirmed) {
            this.$Swal.fire({
              icon: 'success',
              html: `<h3>고객님이 보유하고 계신 무상 금액 ${this.nonsettleFreePoint.toLocaleString()} 원이 신청됐습니다.<br/>총 잔액을 확인하세요!</h3>`,
              confirmButtonText: '창닫기'
            });
          }
        });
    }
  }
};
</script>
<style></style>
