<template>
  <div>
    <v-row class="mt-2 mb-1">
      <v-col cols="12">
        <div class="filters mt-5 mb-8">
          <a-descriptions bordered>
            <a-descriptions-item label="결제 일시">
              <FilterDatePicker
                :style="{ display: 'inline-block' }"
                :prefix="7"
                @setDateRanges="
                  v => {
                    payload.dates = v;
                  }
                "
              />
            </a-descriptions-item>
            <a-descriptions-item label="결제 상태">
              <a-select
                mode="multiple"
                :style="{ minWidth: '150px' }"
                v-model="payload.statusFlag"
              >
                <a-select-option
                  v-for="(flag, index) in PGOrderStatusFlags"
                  :key="index"
                  :value="flag.value"
                >
                  {{ flag.text }}
                </a-select-option>
              </a-select>
            </a-descriptions-item>

            <a-descriptions-item label="주문번호">
              <a-input v-model="payload.orderNo" />
            </a-descriptions-item>
          </a-descriptions>

          <div class="text-center mt-3">
            <v-btn
              color="#00d1b2"
              class="white--text"
              depressed
              :loading="loading"
              :disabled="loading"
              @click="getPointOrders"
            >
              검색
            </v-btn>
          </div>
        </div>
      </v-col>
    </v-row>

    <v-skeleton-loader v-if="loading" type="table" />

    <div v-else>
      <a-table
        bordered
        :columns="columns"
        :data-source="buckets"
        :pagination="false"
        :locale="{
          emptyText: `내역이 존재하지 않습니다.`
        }"
      >
        <template slot="localeString" slot-scope="text">
          {{ text ? text.toLocaleString() : '-' }}
        </template>
        <template slot="money" slot-scope="text">
          {{ text | KRW }}
        </template>
        <template slot="status" slot-scope="text, record">
          <v-chip
            v-if="record.statusObj"
            :color="record.statusObj.color"
            outlined
            dark
            small
            label
          >
            {{ record.statusObj.text }}
          </v-chip>
          <template v-else> {{ text }} </template>
        </template>
        <template slot="hasBill" slot-scope="value, record">
          <v-tooltip v-if="value" bottom>
            <template v-slot:activator="{ on, attrs }">
              <v-btn
                v-bind="attrs"
                v-on="on"
                icon
                @click="handleDetailClick(record.orderNo)"
              >
                <v-icon>mdi-clipboard-text-search-outline</v-icon>
              </v-btn>
            </template>
            <span>자세히보기</span>
          </v-tooltip>
        </template>
      </a-table>
    </div>

    <v-row class="mt-5">
      <v-col>
        <v-pagination
          v-model="payload.page"
          :length="pagination.totalPage"
          :total-visible="10"
          color="secondary"
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { PGOrderStatusFlags } from '@/config/charge';

export default {
  components: {
    FilterDatePicker: () => import('@/components/FilterDatePicker.vue')
  },
  computed: {
    ...mapGetters({
      buckets: 'memberPaymentPointOrder/buckets',
      loading: 'memberPaymentPointOrder/loading',
      columns: 'memberPaymentPointOrder/columns',
      pagination: 'memberPaymentPointOrder/pagination',
      payload: 'memberPaymentPointOrder/payload'
    })
  },
  data() {
    return {
      PGOrderStatusFlags: PGOrderStatusFlags
    };
  },
  watch: {
    payload: {
      immediate: true,
      deep: true,
      handler() {
        this.getPointOrders();
      }
    }
  },
  mounted() {
    this.getPointOrders();
  },
  methods: {
    ...mapActions({
      openCreditCardBillDialog: 'dialog/openCreditCardBillDialog',
      setOrderNo: 'point/setOrderNo',
      getPointOrders: 'memberPaymentPointOrder/index'
    }),
    handleDetailClick(orderNo) {
      if (orderNo == undefined || orderNo == null) {
        return false;
      }

      this.setOrderNo(orderNo).then(() => {
        this.openCreditCardBillDialog(orderNo);
      });
    }
  }
};
</script>

<style></style>
